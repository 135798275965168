<template>
  <v-dialog v-model="visible" width="1800"
            @click:outside="$emit('closed')"
            @keydown.esc="$emit('closed')"
  >
    <v-card v-if="loaded" :loading="generatingPDF">
      <v-card-title class="card-title elevation-3">
        <span class="mr-3">Заказ #{{ current.id }} от {{ current.createdDate | format }}</span>
        <v-chip
            class="mr-3"
            small
            :color="orderStatusColorEnums[current.status]"
        >
          {{ current?.cancellationReason === 'CANCELED_BY_CLIENT' ? 'Отменен клиентом' :orderStatusEnums[current.status] }}
        </v-chip>
        <v-chip
            v-if="current.onOrderChangeConfirm"
            class="mr-3"
            text-color="white"
            small
            color="#F794A0"
        >
          На подтверждении
        </v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closed')">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text class="mt-5">
        <v-container fluid>
          <v-row>
            <v-col v-if="!!cols" cols="3">
              <v-card
                :loading="isContactInfoChanging"
                :elevation="isContactInfoEditing ? 4 : 0">
                <v-card-text>
                  <v-form>
                    <v-text-field
                      label="Покупатель"
                      :value="format(current.user.username)"
                      :disabled="isContactInfoEditing"
                      readonly
                    />
                    <v-text-field
                      label="Тип оплаты"
                      :value="paymentTypeEnums[current.paymentType]"
                      :disabled="isContactInfoEditing"
                      readonly
                    />
                    <v-text-field
                      label="Банк"
                      :value="current.bank"
                      :disabled="isContactInfoEditing"
                      readonly
                    />
                    <v-text-field
                      label="Статус оплаты"
                      :value="enums[current.paymentStatus]"
                      :disabled="isContactInfoEditing"
                      readonly
                    />
                    <v-text-field
                      label="Контактное лицо"
                      v-model="current.address.contactPerson"
                      :readonly="!isContactInfoEditing"
                    ></v-text-field>
                    <v-text-field
                      label="Контактный телефон"
                      v-model="current.address.contactPhone"
                      :readonly="!isContactInfoEditing"
                    ></v-text-field>
                    <v-text-field
                      label="Адрес доставки"
                      :value="fullAddress"
                      :append-outer-icon="isContactInfoEditing ? 'mdi-pencil' : ''"
                      hint="Для изменения нажмите на иконку"
                      @click:append-outer="editContactInfoAddress"
                      readonly
                    >
                    </v-text-field>
                    <v-expand-transition
                      appear
                      v-for="adr in addressSplit"
                      :key="adr.value">
                      <v-text-field
                        v-show="isAddressEditing"
                        :label="adr.title"
                        v-model="current.address[adr.value]"
                      >
                      </v-text-field>
                    </v-expand-transition>
                    <v-text-field
                      :readonly="!isContactInfoEditing"
                      :disabled="!current.comment && !isContactInfoEditing"
                      label="Комментарий к заказу"
                      v-model="current.comment">
                    </v-text-field>
                    <v-text-field
                      v-if="current.cancellationComment"
                      label="Причина отмены"
                      :value="current.cancellationComment"
                      readonly
                    />
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer/>
                  <v-btn
                    v-if="(current.paymentStatus === 'PAID' || current.paymentType === 'POS')
                        && (current.status === 'NEW' || current.status === 'ON_SELECTION')"
                    rounded
                    :disabled="isContactInfoChanging || isChief"
                    @click="editContactInfo">
                    <v-icon>{{ isContactInfoEditing ? 'mdi-close' : 'mdi-pencil' }}</v-icon>
                    <span class="px-2">
                      {{ isContactInfoEditing ? 'Отмена' : 'Ред. данные' }}
                    </span>
                  </v-btn>
                  <v-btn
                    rounded
                    color="primary"
                    class="mx-4"
                    transition="scale-transition"
                    v-show="isContactInfoEditing"
                    :loading="isContactInfoChanging"
                    @click="saveContactInfoChanges">
                    <v-icon>mdi-content-save</v-icon>
                    <span class="px-2">
                      Сохранить
                    </span>
                  </v-btn>
                </v-card-actions>
              </v-card>
              <div>
                <vue-html2pdf
                  :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="true"
                  :paginate-elements-by-height="1100"
                  :filename="`Отбор заказа ${current.id}`"
                  :pdf-quality="2"
                  :manual-pagination="true"
                  pdf-format="a4"
                  pdf-orientation="portrait"
                  pdf-content-width="800px"
                  @progress="onProgress($event)"
                  @startPagination="startPagination()"
                  @hasDownloaded="hasDownloaded($event)"
                  ref="html2Pdf"
                >
                  <pdf-content
                    @domRendered="domRendered()"
                    slot="pdf-content"
                    :data="current"
                  />
                </vue-html2pdf>
              </div>
              <v-card class="confirm-card" v-if="current.onOrderChangeConfirm">
                <v-card-text>
                  Количество товаров в заказе изменилось. Подтвердить заказ?
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn @click="decline">Нет</v-btn>
                  <v-btn @click="confirm" color="success">Да</v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col v-else cols="12" class="d-flex justify-start">
              <v-text-field
                style="max-width: 200px"
                class="mr-3"
                label="Тип оплаты"
                :value="paymentTypeEnums[current.paymentType]"
                :disabled="isContactInfoEditing"
                readonly
              />
              <v-text-field
                style="max-width: 200px"
                label="Статус оплаты"
                :value="enums[current.paymentStatus]"
                :disabled="isContactInfoEditing"
                readonly
              />
            </v-col>
            <v-col :cols="12 - cols">
              <v-card elevation="2">
                <v-card-title>
                  <v-btn icon @click="hide()">
                    <v-icon>
                      {{ cols === 3 ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right' }}
                    </v-icon>
                  </v-btn>
                  Товары
                </v-card-title>
                <v-card-text>
                  <crm-table
                    :headers="headers"
                    :data="current.items"
                    @handleActions="handle"
                  >
                  </crm-table>
                  <p><strong>Отмененые</strong></p>
                  <crm-table
                    :headers="cancelledTableHeaders"
                    :data="cancelledItems"
                    :config="cancelledTableConfig"
                    @handleActions="handle"
                  >
                  </crm-table>
                </v-card-text>
                <v-alert
                  v-if="current && current.items"
                  text
                  dense
                  class="text-end"
                  :type="isSelecting && amountLowThanPlan ? 'error' : 'info'">
                  Общее кол-во товаров, шт.: <strong>{{ getTotalQuantity() }}</strong>
                  Отобрано, шт.: <strong>{{ getSelectedQuantity() }}</strong>
                  <span v-if="current.promo">
                    Промокод: <strong> -{{ current.promo.event.discount }} тг.</strong>
                  </span>
                  Итоговая сумма: <strong>{{ current.totalPrice }} тг.</strong>
                </v-alert>
                <v-alert
                  :value="showAmountDiscrepancyAlert && current.paymentType !== 'CREDIT'"
                  prominent
                  border="left"
                  type="error"
                  transition="scale-transition"
                  dismissible
                  @input="closeAlert"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      Вы согласны завершить заказ с отклонением?
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        @click="confirmAmountDiscrepancy"
                        color="primary">
                        Да
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
                <v-alert
                  :value="showCreditItemsAlert"
                  prominent
                  border="left"
                  type="error"
                  transition="scale-transition"
                  dismissible
                  @input="closeCreditAlert"
                >
                  <v-row align="center">
                    <v-col class="grow">
                      Заказ оформлен в рассрочку. Не все позиции отобраны.
                      Вы действительно хотите отменить заказ?
                    </v-col>
                    <v-col class="shrink">
                      <v-btn
                        @click="checkSelectedProducts"
                        color="primary">
                        Да
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="pb-5 px-8">
        <v-spacer></v-spacer>
        <v-btn
            v-if="current.status === 'NEW' &&
                  (current.paymentStatus !== 'UNPAID' ||
                  current.paymentType === 'POS') &&
                  (availablePages[$route.name].update ||
                  isWarehouseManage) &&
                  current.paymentStatus !== 'WAITING'"
            rounded
            color="primary"
            @click="startSelection">
          <v-icon>mdi-printer</v-icon>
          <span class="px-2">Начать отбор</span>
        </v-btn>
        <v-btn
            v-if="current.status === 'WAITING' &&
                  (isCallCenterOperator || availablePages[$route.name].update)"
            rounded
            @click="decline">
          <span class="px-2">Отменить заказ</span>
        </v-btn>
        <v-btn
            v-if="current.status === 'WAITING' &&
                  (isCallCenterOperator || availablePages[$route.name].update)"
            rounded
            color="primary"
            @click="confirm">
          <span class="px-2">Подтвердить</span>
        </v-btn>
        <v-btn v-if="current.status === 'ON_SELECTION' &&
        isSelecting &&
        (availablePages[$route.name].update ||
        isWarehouseManage)"
               rounded
               color="primary"
               :loading="generatingPDF"
               :disabled="showAmountDiscrepancyAlert || showCreditItemsAlert"
               @click="checkSelectedProducts">
          <span class="px-2">Завершить отбор</span>
        </v-btn>
        <v-btn
          v-if="current.status === 'ON_DELIVERY' &&
            (availablePages[$route.name].update ||
            (!(current.receiptType === 'DELIVERY') && isPickUpPointMan) ||
            isCallCenterOperator)"
          rounded
          color="primary"
          @click="openCompleteConfirmDialog">
          <span class="px-2">Завершить заказ</span>
        </v-btn>
        <v-btn
          v-if="editing"
          @click="saveOrder"
          color="#007450"
          style="color: #FFFFFF; border-radius: 18px"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
    <cancellation-confirm
      v-if="current"
      :open="showCancellationDialog"
      :orderItem="cancellationItem"
      :paymentType="current.paymentType"
      :orderStatus="current.status"
      @close="onCancellationDialogClose"
      @decline="decline"
    />
    <complete-confirm v-if="current"
                      :open="completeConfirmDialogOpened"
                      :orderId="current.id"
                      :products="confirmingProducts"
                      :paymentType="current.paymentType"
                      :orderStatus="current.status"
                      :receiptType="current.receiptType"
                      :user="current.user"
                      :contactPhone="current.address.contactPhone"
                      @confirm="confirmComplete"
                      @updateOrder="fetchData"
                      @close="closeCompleteDialog"/>
    <selection-confirm v-if="current"
                       :open="selectionConfirmDialogOpened"
                       @confirm="confirmSelection"
                       @close="closeSelectionDialog"/>
  </v-dialog>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import moment from 'moment';
import VueHtml2pdf from 'vue-html2pdf';
/** TODO: remove this package */
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { mapState } from 'vuex';
import CancellationConfirm from '@/views/crm/orders/components/CancellationConfirm.vue';
import SelectionConfirm from '@/views/crm/orders/components/SelectionConfirm.vue';
import CompleteConfirm from './components/CompleteConfirm.vue';
import PdfContent from './components/PdfContent.vue';

export default {
  name: 'orderInfo',
  props: {
    visible: {
      default: false,
    },
    id: {
      default: null,
    },
  },
  components: {
    VueHtml2pdf,
    CompleteConfirm,
    CancellationConfirm,
    SelectionConfirm,
    PdfContent,
    'crm-table': crmTable,
  },
  filters: {
    format(val) {
      if (moment(val).isValid()) {
        return moment(val, 'YYYY-MM-DDTHH:mm:ss.sss[Z]').format('DD-MM-YYYY');
      }
      return '';
    },
  },
  data() {
    return {
      generatingPDF: false,
      amountLowThanPlan: false,
      showAmountDiscrepancyAlert: false,
      cancelledTableHeaders: [],
      cols: 3,
      headers: [
        {
          value: 'vendorCode', sortable: false, text: 'Номенклатура', type: 'defaultItem',
        },
        {
          value: 'nomenclatureCode', sortable: false, text: 'Артикул товара', type: 'defaultItem',
        },
        {
          value: 'barcode', sortable: false, text: 'Штрих-код', type: 'defaultItem',
        },
        {
          value: 'nameRu', sortable: false, text: 'Наименование', type: 'defaultItem',
        },
        {
          value: 'imageUrl.preAssignedUrl', sortable: false, text: 'Фото', type: 'tableImage',
        },
        {
          value: 'planAmount', sortable: false, text: 'Кол-во заказано', type: 'defaultItem',
        },
        {
          value: 'price', sortable: false, text: 'Цена за товар', type: 'defaultItem',
        },
        {
          value: 'totalPrice', sortable: false, text: 'Стоимость заказано', type: 'defaultItem',
        },
        {
          value: 'supplier.name', sortable: false, text: 'Продавец', type: 'defaultItem',
        },
        {
          value: 'selectedAmount',
          sortable: false,
          text: 'Отобрано',
          type: 'tableNumberField',
          maxValueProperty: 'planAmount',
        },
        {
          value: 'factAmount',
          text: 'Доставлено',
          type: 'defaultItem',
          sortable: false
        }
      ],
      actionsHeaders: [
        {
          value: 'actions',
          sortable: false,
          text: 'Действие',
          type: 'actions',
          align: 'end',
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
            },
            {
              label: 'Удалить',
              event: 'remove',
              icon: 'mdi-delete',
            },
          ],
        },
      ],
      cancelActionChild: {
        text: 'Действие',
        value: 'actions',
        type: 'actions',
        sortable: false,
        child: [
          {
            label: 'Отменить позицию',
            event: 'cancel',
            icon: 'mdi-close',
            availableConditionName: 'cancellable',
          },
        ],
      },
      current: null,
      cancelledItems: [],
      menu2: false,
      shipmentDate: null,
      acceptDialog: false,
      orderId: null,
      loaded: false,
      editing: false,
      cancelledTableConfig: {
        add: false,
        search: false,
        pageable: true,
        hideToolbar: true,
        hidePagination: false,
      },
      completeConfirmDialogOpened: false,
      isSelecting: false,
      previousFormState: {},
      isContactInfoChanging: false,
      isContactInfoEditing: false,
      isAddressEditing: false,
      addressSplit: [
        {
          value: 'city',
          title: 'Город',
        }, {
          value: 'street',
          title: 'Улица',
        }, {
          value: 'building',
          title: 'Дом',
        }, {
          value: 'apartment',
          title: 'Квартира',
        },
      ],
      showCancellationDialog: false,
      cancellationItem: {},
      showCreditItemsAlert: false,
      startSelectionFlag: false,
      selectionConfirmDialogOpened: false,
    };
  },
  async created() {
    /** Хэдэр без экшнов и отключенным редактированием "Отобрано" (тип: defaultItem) */
    this.cancelledTableHeaders = this.headers
      .map((obj) => (obj.value === 'selectedAmount' ? { ...obj, type: 'defaultItem' } : obj));
    this.$loading(true);
    this.orderId = this.id;
    await this.fetchData();
  },
  watch: {
    amountLowThanPlan(val) {
      if (this.current.status === 'ON_SELECTION' && this.current.paymentType === 'CREDIT') {
        this.showCreditItemsAlert = val;
      }
    },
  },
  computed: {
    ...mapState(['user']),
    paymentTypeEnums() {
      return this.$store.getters['entity/getEntity']('paymentTypeEnums');
    },
    orderStatusEnums() {
      return this.$store.getters['entity/getEntity']('orderStatusEnums');
    },
    orderStatusColorEnums() {
      return this.$store.getters['entity/getEntity']('orderStatusColorEnums');
    },
    enums() {
      return this.$store.getters['entity/getEntity']('enums');
    },
    fullAddress() {
      return `${this.current.address?.city}, ${this.current.address?.street}, ${this.current.address?.building}, ${this.current.address?.apartment}`;
    },
    availablePages() {
      return this.$store.getters.user.availablePages;
    },
    confirmingProducts() {
      return this.current.items.map((i) => (
        {
          ...i,
          orderId: this.current.id,
          editing: true,
          error: false,
          rules: {
            factAmount: [
              (v) => !!v || 'Обязательное поле',
              (v) => v >= 0 || 'Не может быть отрицательным числом',
              (v) => v <= i.planAmount || 'Не может быть больше чем количество',
            ],
          },
          cancellable: i.status !== 'CANCELED',
        }
      ));
    },
    isWarehouseManage() {
      return this.user.roles.includes('ROLE_WAREHOUSE_MANAGER');
    },
    isPickUpPointMan() {
      return this.user.roles.includes('ROLE_PICKUP_POINT_MANAGER');
    },
    isCallCenterOperator() {
      return this.user.roles.some((role) => ['ROLE_ADMIN', 'ROLE_CALL_CENTER_MANAGER', 'ROLE_CALL_CENTER_OPERATOR'].includes(role));
    },
    isChief() {
      return this.user.roles.includes('ROLE_CHIEF');
    },
  },
  methods: {
    openCompleteConfirmDialog() {
      this.completeConfirmDialogOpened = true;
    },

    handle(event) {
      if (event.type === 'edit') {
        if (this.isChief) return;
        this.editing = true;
        this.current = {
          ...this.current,
          items: this.current.items.map((item) => {
            if (item.productId === event.item.productId) {
              return {
                ...item,
                editing: true,
              };
            }
            return { ...item };
          }),
        };
      }
      if (event.type === 'remove') {
        if (this.isChief) return;
        const { productId } = event.item;
        this.deleteItem(productId);
      }
      if (event.type === 'cancel') {
        if (this.isChief) return;
        this.showCancellationDialog = true;
        this.cancellationItem = event.item;
      }
    },
    checkSelectedProducts() {
      const dtoList = this.current.items.map((product) => ({
        productId: product.productId,
        factAmount: product.selectedAmount,
      }));

      let message = 'Возникла ошибка';
      if (this.current.items.some((i) => !i.selectedAmount)) {
        message = 'Количество не отобрано';
      } else if (this.current.items.some((i) => i.selectedAmount > i.planAmount)) {
        message = 'Укажите корректное количество';
      } else if (this.amountLowThanPlan && this.current.paymentType !== 'CREDIT') {
        this.showAmountDiscrepancyAlert = true;
        return;
      } else if (this.amountLowThanPlan && this.current.paymentType === 'CREDIT') {
        this.selectionConfirmDialogOpened = true;
      } else {
        this.confirmComplete(dtoList);
        return;
      }
      this.$store.dispatch('tools/setSnackbar', {
        type: 'error',
        message,
      });
    },
    confirmAmountDiscrepancy() {
      this.amountLowThanPlan = false;
      this.showAmountDiscrepancyAlert = false;
      this.checkSelectedProducts();
    },
    closeAlert(v) {
      this.showAmountDiscrepancyAlert = v;
    },
    closeCreditAlert(v) {
      this.showCreditItemsAlert = v;
    },
    getTotalQuantity() {
      return this.current.items.reduce((acc, cur) => acc + cur.planAmount, 0);
    },
    getSelectedQuantity() {
      const selectedTtl = this.current.items
        .reduce((acc, cur) => acc + (cur.selectedAmount || 0), 0);
      if (this.current.items.every((el) => el.selectedAmount !== undefined)) {
        this.amountLowThanPlan = selectedTtl < this.getTotalQuantity();
      }
      return selectedTtl || 0;
    },
    confirmComplete(dtoList) {
      if (this.current.status === 'CANCELED') {
        this.completeConfirmDialogOpened = false;
        return
      }

      this.$loading(true);
      const urlPath = this.current.status === 'ON_DELIVERY' ? 'delivered' : 'selected';
      this.$api.post(`/api/v1/crm/orders/${urlPath}/${this.current.id}`, dtoList)
        .then((res) => {
          this.current = res;
          this.completeConfirmDialogOpened = false;
        })
        .catch(async (error) => {
          if (error.response.data.error === 'order.not-paid') {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'Заказ не оплачен',
            });
          }
          if (error.response.data.error === 'order-item-fact-amount.not-valid') {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'Неправильно введено фактическое количество',
            });
          }
          if (error.response.status === 401) {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'У вас нет прав для закрытия заказа',
            });
          }
        })
        .finally(() => {
          this.$loading(false);
          this.$emit('closed');
        });
    },
    closeCompleteDialog() {
      this.completeConfirmDialogOpened = false;
    },
    closeSelectionDialog() {
      this.selectionConfirmDialogOpened = false;
    },
    confirmSelection() {
      const dtoList = this.current.items.map((product) => ({
        productId: product.productId,
        factAmount: product.selectedAmount,
      }));
      this.confirmComplete(dtoList);
    },
    closeOrder(order) {
      this.$api.post(`/api/v1/crm/orders/delivered/${order.id}`).then((res) => {
        this.current = res;
      })
        .catch(async (error) => {
          if (error.response.data.error === 'order.not-paid') {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'Заказ не оплачен',
            });
          }
          if (error.response.status === 401) {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'У вас нет прав для закрытия заказа',
            });
          }
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    saveOrder() {
      this.current.items.forEach((item) => {
        this.$loading(true);
        if (item.comment) {
          this.saveEditing(item.comment, this.current.id, item.productId);
        } else {
          this.$loading(false);
        }
      });
    },
    saveEditing(comment, orderId, productId) {
      this.$api
        .post('/api/v1/crm/orders/edit', {
          comment,
          orderId,
          productId,
        })
        .then((res) => {
          this.current = res;
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    deleteItem(productId) {
      this.$loading(true);
      this.$api
        .delete(`/api/v1/crm/orders/orderId/${this.current.id}/productId/${productId}`)
        .then((res) => {
          this.current = res;
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    decline() {
      this.$loading(true);
      this.$api
        .post(`/api/v1/crm/orders/decline/${this.orderId}`)
        .then(() => {
          /** FIXME: response is empty */
          // this.current = res;
          this.fetchData();
        })
        .finally(() => {
          this.$loading(false);
          this.$emit('closed');
        });
    },
    confirm() {
      this.$loading(true);
      this.$api
        .post(`/api/v1/crm/orders/confirm/${this.orderId}`)
        .then(() => {
          /** FIXME: response is empty */
          // this.current = res;
          this.fetchData();
        })
        .finally(() => {
          this.$loading(false);
          this.$emit('closed');
        });
    },
    async startSelection() {
      this.startSelectionFlag = true;
      this.$loading(true);
      await this.$api
        .post(`/api/v1/crm/orders/start-selection/${this.orderId}`)
        .then((res) => {
          this.current = res;
          this.filterCurrentItems();
          this.generateReport();
        })
        .finally(() => {
          this.$loading(false);
        });
      this.fetchData();
    },
    endSelection() {
      this.isSelecting = true;
      const editableItems = this.current.items.map((item) => ({
        ...item,
        editing: true,
        selectedAmount: item.selectedAmount || undefined,
        rules: {
          selectedAmount: [
            (v) => !!v || 'Укажите количество',
            (v) => v <= item.planAmount || 'Отобрано свыше количества',
          ],
        },
      }));
      this.current = { ...this.current, items: editableItems };
    },
    /** Отделяем отмененные позиции в отдельный массив @cancelledItems */
    filterCurrentItems() {
      this.cancelledItems = this.current.items.filter((item) => item.status === 'CANCELED');
      const cancellableItems = this.current.items
        .filter((item) => item.status !== 'CANCELED')
        .map((i) => ({
          ...i,
          orderId: this.current.id,
          cancellable: true,
          editing: false,
        }));
      this.current = { ...this.current, items: cancellableItems };
    },
    fetchData() {
      this.$api
        .get(`/api/v1/crm/orders/${this.orderId}`)
        .then((res) => {
          this.current = { ...res };
          this.filterCurrentItems();
          if (this.current.onOrderChangeConfirm) {
            this.headers = [...this.headers, ...this.actionsHeaders];
          }
          /** Отмена позиции оплаченных заказов для статусов Новый и На отборе
           * Для Завершенных отображается в CompleteConfirm.vue
           * Редактируется @headers таблицы, доб. Действия. Если @cancellable,
           * то отображать иконку (Х) */
          const isPaid = this.current.paymentStatus === 'PAID';
          if ((this.current.status === 'NEW' || this.current.status === 'ON_SELECTION')
            && (isPaid || this.current.paymentType === 'POS')
            && !this.startSelectionFlag) {
            this.headers = [...this.headers, this.cancelActionChild];
          }
          if (this.current.status === 'ON_SELECTION') {
            this.endSelection();
          }
        })
        .catch(() => {
          this.$router.replace({
            name: 'orders',
          });
        })
        .finally(() => {
          this.$loading(false);
          this.loaded = true;
        });
    },
    editContactInfoAddress() {
      this.isAddressEditing = !this.isAddressEditing;
    },
    editContactInfo() {
      this.isContactInfoEditing = !this.isContactInfoEditing;
      if (this.isContactInfoEditing) {
        /** TODO: save previous state, if user press cancel to reset form */
        // this.previousFormState = {...};
      } else {
        this.isAddressEditing = false;
      }
    },
    saveContactInfoChanges() {
      const { id, address, comment } = this.current;
      const {
        city, street, building, apartment, contactPerson, contactPhone,
      } = address;
      const body = {
        id,
        comment,
        addressDto: {
          city, street, apartment, building, contactPerson, contactPhone,
        },
      };

      this.$api
        .post('api/v1/crm/orders/order-edit', body)
        .then((res) => {
          this.current = { ...res };
          this.filterCurrentItems();
          if (this.current.status === 'ON_SELECTION') {
            this.endSelection();
          }
        })
        .catch(() => {})
        .finally(() => {
          this.isAddressEditing = false;
          this.isContactInfoEditing = false;
        });
    },
    generateReport() {
      this.$refs.html2Pdf.generatePdf();
    },

    onProgress(progress) {
      this.progress = progress;
    },
    startPagination() {
      this.generatingPDF = true;
    },
    hasDownloaded(blobPdf) {
      console.log(blobPdf);
      this.generatingPDF = false;
    },
    domRendered() {
      this.contentRendered = true;
    },
    onCancellationDialogClose(data) {
      if (data && data.productId) {
        /** Находим отменный товар, добавляем в таблицу Отмененные */
        const newCancelledItems = this.current.items.filter((i) => i.productId === data.productId);
        this.cancelledItems = [...this.cancelledItems, ...newCancelledItems];
        /** Обновляем основную таблицу, фильтруя уже отмененные позиции */
        const cancellableItems = this.current.items
          .filter((item) => item.productId !== data.productId)
          .map((i) => ({
            ...i,
            orderId: this.current.id,
            cancellable: true,
          }));
        if (cancellableItems.length === 0) {
          this.current.status = 'CANCELED';
        }
        this.current = { ...this.current, items: cancellableItems };
      }
      this.showCancellationDialog = false;
      this.cancellationItem = {};
    },
    format(str) {
      const cleaned = (`${str}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        const intCode = (match[1] ? '+' : '');
        return [intCode, match[1], ' ', match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('');
      }
      return `${str}`;
    },
    hide() {
      this.cols = !!this.cols === true ? 0 : 3;
    },
  },
};
</script>

<style scoped>
.card-title {
  height: 70px;
}
.confirm-card {
  padding: 20px;
  border-radius: 12px;
}
</style>
