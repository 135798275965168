var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"content-class":"complete-order-dialog"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.$emit('close')},"click:outside":function($event){return _vm.$emit('close')}},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{staticClass:"complete-order-dialog"},[_c('v-card-title',[_vm._v(_vm._s(_vm.title))]),_c('v-card-text',[_c('hint-text',[_c('span',[_vm._v(" Введите "),_c('b',[_vm._v("фактическое количество")]),_vm._v(" по каждой позиции, затем "),_c('b',[_vm._v("подтвердите")]),_vm._v(". ")])]),_c('v-card',{attrs:{"elevation":"1"}},[_c('crm-table',{attrs:{"headers":_vm.headers,"data":_vm.localProducts},on:{"handleActions":_vm.handle}}),_c('v-alert',{attrs:{"text":"","dense":""}},[_vm._v(" Итоговая сумма: "),_c('strong',[_vm._v(_vm._s(_vm.getOrderSum())+" тг.")])])],1),(_vm.orderStatus === 'ON_DELIVERY' &&
                _vm.paymentType === 'POS' &&
                _vm.receiptType ==='PICKUP')?_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-checkbox',{staticClass:"mt-2",attrs:{"label":"Подтвердить оплату"},model:{value:(_vm.isOrderPayed),callback:function ($$v) {_vm.isOrderPayed=$$v},expression:"isOrderPayed"}})],1)],1):_vm._e()],1),_c('v-card-actions',{staticClass:"complete-order-dialog__actions"},[_c('v-btn',{staticClass:"complete-order-dialog__actions--cancel",attrs:{"text":""},on:{"click":function($event){return _vm.$emit('close')}}},[_c('span',{staticClass:"px-2"},[_vm._v("Отменить")])]),_c('v-btn',{staticClass:"complete-order-dialog__actions--save",attrs:{"rounded":"","color":"primary","disabled":!this.localProducts.some((i) => i.status !== 'CANCELLED') ||
        (_vm.paymentType === 'POS' &&
        _vm.receiptType ==='PICKUP' &&
        _vm.orderStatus === 'ON_DELIVERY' &&
        !_vm.isOrderPayed) ||
        _vm.clicked},on:{"click":_vm.confirmComplete}},[_c('span',{staticClass:"px-2"},[_vm._v("Подтвердить")])])],1)],1),_c('cancellation-confirm',{attrs:{"open":_vm.showCancellationDialog,"orderItem":_vm.cancellationItem,"paymentType":_vm.paymentType,"orderStatus":_vm.orderStatus},on:{"cancelAll":_vm.cancelAll,"close":_vm.onCancellationDialogClose}}),_c('sms-code',{attrs:{"open":_vm.showSMSCode,"phone-number":_vm.user.username},on:{"close":_vm.closeSMSDialog,"refresh":_vm.sendSMS,"confirm":_vm.complete}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }