<template>
  <v-dialog v-model="open"
            @keydown.esc="$emit('close')"
            @click:outside="$emit('close')"
            width="600px">
    <v-card :loading="loading">
      <v-card-title>
        <v-icon class="mx-1" color="error">
          mdi-alert
        </v-icon>
        Отмена позиции
      </v-card-title>
      <v-card-text v-if="!reasonView">
        Вы действительно хотите отменить данную позицию <strong>{{ orderItem.nameRu }}</strong>?
        <p v-if="paymentType === 'CREDIT'" class="alert">
          Заказ оформлен в рассрочку. Отмена позиции приведет к отмене заказа.
        </p>
      </v-card-text>
      <v-card-text v-else>
        <v-form ref="form">
          <v-radio-group
            v-model="cancellationReason"
            label="Выберите причину отмены"
            :rules="[(v) => !!v || 'Укажите причину']"
            column
          >
            <v-radio
              label="Отмена покупателем"
              value="CANCELED_BY_CLIENT"
            ></v-radio>
            <v-radio
              v-if="orderStatus === 'ON_SELECTION'"
              label="Отмена маркетом"
              value="CANCELED_BY_MARKET"
            ></v-radio>
            <v-text-field
              v-if="cancellationReason === 'CANCELED_BY_MARKET'"
              v-model="cancellationComment"
              label="Примечание"
              :rules="[(v) => !!v || 'Обязательное поле']"
              transition="fade-transition"
            />
          </v-radio-group>
        </v-form>
      </v-card-text>
      <v-card-actions v-if="!reasonView">
        <v-spacer></v-spacer>
        <v-btn color="primary"  @click="reasonView = true">
          Да
        </v-btn>
        <v-btn text @click="$emit('close')">
          Нет
        </v-btn>
      </v-card-actions>
      <v-card-actions v-else>
        <v-spacer></v-spacer>
        <v-btn text @click="reasonView = false">
          Назад
        </v-btn>
        <v-btn color="primary" @click="confirmCancellation">
          Подтвердить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'CancellationConfirm',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
    orderItem: {
      type: Object,
      default: () => {},
    },
    paymentType: {
      type: String,
      default: '',
    },
    orderStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      reasonView: false,
      cancellationReason: '',
      cancellationComment: '',
    };
  },
  methods: {
    confirmCancellation() {
      if (this.orderStatus === 'ON_DELIVERY' && this.paymentType === 'CREDIT') {
        this.$emit('cancelAll', {
          cancellationComment: this.cancellationComment,
          cancellationReason: this.cancellationReason,
        });
        this.reasonView = false;
        this.$refs.form.reset();
      } else if (this.$refs.form.validate()) {
        this.loading = true;
        const body = {
          cancellationComment: this.cancellationComment,
          cancellationReason: this.cancellationReason,
          orderId: this.orderItem.orderId,
          productId: this.orderItem.productId,
        };
        this.$api.post('/api/v1/crm/orders/canceled-order-item', body)
          .then(() => {
            this.$refs.form.reset();
            this.reasonView = false;
            this.$emit('close', { productId: this.orderItem.productId });
          })
          .catch(async (error) => {
            if (error) {
              await this.$store.dispatch('tools/setSnackbar', {
                type: 'error',
                message: 'При отмене позиции возникла ошибка',
              });
            }
          })
          .finally(() => {
            this.$loading(false);
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
p.alert {
  background-color: #f15b3b;
  line-height: 1.4;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
</style>
