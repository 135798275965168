<template>
  <v-dialog v-model="open"
            @keydown.esc="close"
            content-class="sms-code-dialog"
            max-width="340px"
            persistent>
    <v-card class="sms-code-dialog">
      <v-card-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="close">
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="text-center">
        <h3>Введите код из SMS</h3>
        <div class="fieldset">
          <label class="box">
            <input class="field" data-index="0" type="text" placeholder=""
                                    v-on:input="handleInputField"/>
          </label>
          <label class="box">
            <input class="field" data-index="1" type="text" placeholder=""
                                    v-on:input="handleInputField"/>
          </label>
          <label class="box">
            <input class="field" data-index="2" type="text" placeholder=""
                                    v-on:input="handleInputField"/>
          </label>
          <label class="box">
            <input class="field" data-index="3" type="text" placeholder=""
                                    v-on:input="handleInputField" />
          </label>
        </div>
        <div class="phone">
          Код выслан на {{ phone }}
        </div>
        <div v-if="time" class="refresh-code">
          Получить новый код можно через {{ prettyTime }}
        </div>
        <div v-else class="get-code" @click="refreshCode">
          Получить новый код
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { debounce } from 'lodash';

export default {
  name: 'smsCode',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    phoneNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      smsCode: '',
      currentIndex: 0,
      check: {
        code: [],
      },
      arr: ['', '', '', ''],
      time: 0,
      timer: 0,
    };
  },

  computed: {

    phone() {
      return this.format(this.phoneNumber);
    },
    prettyTime() {
      const time = this.time / 60;
      const minute = parseInt(time, 10);
      let sec = Math.round((time - minute) * 60);
      if (sec < 10) {
        sec = `0${sec}`;
      }
      return `${minute}:${sec}`;
    },
  },
  watch: {
    open(val) {
      if (val) {
        this.startTimer();
        this.time = 180;
      }
    },
    arr: {
      handler(val) {
        this.confirm(val);
      },
      deep: true,
    },
  },
  methods: {
    confirm: debounce(function (sms) {
      if (sms.every((el) => typeof (el) === 'number')) {
        this.$emit('confirm', sms.join(''));
        this.reset();
      }
    }, 1000),
    handleInputField({ target }) {
      const fields = document.querySelectorAll('.field');
      const value = target.value.slice(0, 1);
      // eslint-disable-next-line no-param-reassign
      target.value = value;

      const step = value ? 1 : -1;
      this.arr.splice([target.dataset.index], 1, value ? +value : '');
      const fieldIndex = [...fields].findIndex((field) => field === target);
      const focusToIndex = fieldIndex + step;

      if (focusToIndex < 0 || focusToIndex >= fields.length) return;

      fields[focusToIndex].focus();
    },
    format(str) {
      const cleaned = (`${str}`).replace(/\D/g, '');
      const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
      if (match) {
        const intCode = (match[1] ? '+' : '');
        return [intCode, match[1], ' ', match[2], ' ', match[3], ' ', match[4], ' ', match[5]].join('');
      }
      return `${str}`;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          // eslint-disable-next-line no-plusplus
          this.time--;
        } else {
          clearInterval(this.timer);
          this.reset();
        }
      }, 1000);
    },
    stop() {
      clearInterval(this.timer);
      this.timer = null;
    },
    reset() {
      this.stop();
      this.time = 0;
    },
    close() {
      this.reset();
      this.$emit('close');
    },
    refreshCode() {
      this.reset();
      this.$emit('refresh');
    },
  },
};
</script>
<style lang="scss">
.sms-code-dialog h3 {
  font-size: 20px;
  margin: 0 0 14px 0;
}
.phone {
  font-size: 18px;
  color: #58595B;
  margin: 14px 0;
  font-weight: 400;
}
.refresh-code, .get-code {
  font-size: 14px;
  font-weight: 300;
}
.get-code {
  color: #4CAF50;
  text-decoration: underline;
  cursor: pointer;
}
.fieldset {
  position: relative;
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 12px;
  border-radius: 6px;
  overflow: hidden;
  will-change: transform;
  /* make shadows of inner elements visible */
  padding: 6px;
  margin: -6px;
}
.fieldset::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  transform: translateX(-100%);
}
.box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 6px;
  overflow: hidden;
  will-change: transform;
}
.box:focus-within {
  box-shadow: 0 0 6px 1px hsla(240, 54%, 61%, 0.2), 0 0 0 2px hsla(240, 54%, 61%, 0.6);
}
.box::before,
.box::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  border-radius: 6px;
  overflow: hidden;
}
.box::before {
  background: #EAECEE;
  z-index: 1;
  transition: background-color 450ms;
}
.box::after {
  transform: translateY(100%);
  background-color: hsl(145, 0%, 42%);
  opacity: 0;
  z-index: var(--z-index-sm);
  transition: transform 450ms,
  opacity 450ms, background-color 450ms;
}

.field {
  position: relative;
  border: 0;
  outline: 0;
  font-size: 25.21px;
  line-height: 42px;
  color: #000000;
  background-color: transparent;
  text-align: center;
  z-index: 100;
}
.field::placeholder {
  color: hsl(240, 54%, 87%);
}
</style>
