<template>
  <v-dialog v-model="open"
            @keydown.esc="$emit('close')"
            @click:outside="$emit('close')"
            content-class="complete-order-dialog">
    <v-card class="complete-order-dialog">
      <v-card-title>{{title}}</v-card-title>
      <v-card-text>
        <hint-text>
          <span>
            Введите <b>фактическое количество</b> по каждой позиции, затем <b>подтвердите</b>.
          </span>
        </hint-text>
        <v-card elevation="1">
          <crm-table
            :headers="headers"
            :data="localProducts"
            @handleActions="handle"
          ></crm-table>
          <v-alert text dense>
            Итоговая сумма: <strong>{{ getOrderSum() }} тг.</strong>
          </v-alert>
        </v-card>
        <v-row
          v-if="orderStatus === 'ON_DELIVERY' &&
                  paymentType === 'POS' &&
                  receiptType ==='PICKUP'"
        >
          <v-col cols="auto">
            <v-checkbox v-model="isOrderPayed" label="Подтвердить оплату" class="mt-2"></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="complete-order-dialog__actions">
        <v-btn text class="complete-order-dialog__actions--cancel" @click="$emit('close')">
          <span class="px-2">Отменить</span>
        </v-btn>
        <v-btn
          rounded
          color="primary"
          :disabled="!this.localProducts.some((i) => i.status !== 'CANCELLED') ||
          (paymentType === 'POS' &&
          receiptType ==='PICKUP' &&
          orderStatus === 'ON_DELIVERY' &&
          !isOrderPayed) ||
          clicked"
           class="complete-order-dialog__actions--save" @click="confirmComplete">
          <span class="px-2">Подтвердить</span>
        </v-btn>
      </v-card-actions>
    </v-card>
    <cancellation-confirm
      :open="showCancellationDialog"
      :orderItem="cancellationItem"
      :paymentType="paymentType"
      :orderStatus="orderStatus"
      @cancelAll="cancelAll"
      @close="onCancellationDialogClose"
    />
    <sms-code
      :open="showSMSCode"
      :phone-number="user.username"
      @close="closeSMSDialog"
      @refresh="sendSMS"
      @confirm="complete"
    />
  </v-dialog>
</template>
<script>
import crmTable from '@/components/crm-table/index.vue';
import CancellationConfirm from '@/views/crm/orders/components/CancellationConfirm.vue';
import SmsCode from '@/views/crm/orders/components/smsCode.vue';
import HintText from '../../../../components/HintText.vue';

export default {
  name: 'CompleteConfirm',
  components: {
    SmsCode,
    'crm-table': crmTable,
    HintText,
    CancellationConfirm,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: Number,
      default: 0,
    },
    products: {
      type: Array,
      default: () => [],
    },
    paymentType: {
      type: String,
      default: '',
    },
    orderStatus: {
      type: String,
      default: '',
    },
    receiptType: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      default: () => {},
    },
    contactPhone: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        {
          value: 'imageUrl.preAssignedUrl', sortable: false, text: 'Фото', type: 'tableImage',
        },
        {
          value: 'nameRu', sortable: false, text: 'Наименование', type: 'defaultItem',
        },
        {
          value: 'vendorCode', sortable: false, text: 'Артикул цвета', type: 'defaultItem',
        },
        {
          value: 'barcode', sortable: false, text: 'Штрих-код', type: 'defaultItem',
        },
        {
          value: 'price', sortable: false, text: 'Цена', type: 'defaultItem',
        },
        {
          value: 'planAmount', sortable: false, text: 'Количество', type: 'defaultItem',
        },
        {
          value: 'selectedAmount', sortable: false, text: 'Отобрано', type: 'defaultItem',
        },
        {
          value: 'factAmount',
          sortable: false,
          text: 'Фактическое количество',
          type: 'tableNumberField',
          // width: '300',
          maxValueProperty: 'selectedAmount',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Отменить позицию',
              event: 'cancel',
              icon: 'mdi-close',
              availableConditionName: 'cancellable',
            },
          ],
        },
      ],
      localProducts: [],
      showCancellationDialog: false,
      cancellationItem: {},
      showSMSCode: false,
      dtoList: [],
      messageResponseId: null,
      isOrderPayed: false,
      clicked: false,
    };
  },
  created() {
    // this.localProducts = this.products;
    this.localProducts = this.products
      .map((item) => ({
        ...item,
        cancellable: item.status !== 'CANCELED',
        editing: item.status !== 'CANCELED',
      }));

  },
  computed: {
    title() {
      const title = this.orderStatus === 'ON_DELIVERY' ? 'Завершение' : 'Отбор';
      return `${title} заказа #${this.orderId}`;
    },
  },
  methods: {
    getOrderSum() {
      return this.localProducts.reduce((acc, cur) => {
        const amount = cur.factAmount > cur.planAmount ? 0 : cur.factAmount;
        return acc + cur.price * (amount > 0 ? amount : 0);
      }, 0);
    },
    confirmComplete() {
      ///
      this.clicked = true;
      const dtoList = this.localProducts.map((product) => ({
        productId: product.productId,
        factAmount: product.factAmount,
      }));

      this.localProducts = this.localProducts.map((product) => (
        product.factAmount ? product : { ...product, error: true }
      ));
      let message = 'Возникла ошибка';
      if (this.localProducts.some((i) => (!i.factAmount && i.cancellable) || i.factAmount === 0)) {
        message = 'Заполните фактическое количество или удалите позицию';
        this.clicked = false;
      } else if (this.localProducts.some((i) => i.factAmount > i.planAmount)) {
        message = 'Укажите корректное количество';
        this.clicked = false;
      } else {
        this.verifyComplete(dtoList);
        return;
      }
      this.$store.dispatch('tools/setSnackbar', {
        type: 'error',
        message,
      });
    },
    handle(event) {
      if (event.type === 'cancel') {
        this.showCancellationDialog = true;
        this.cancellationItem = event.item;
      }
    },
    cancelAll({ cancellationComment, cancellationReason }) {
      const body = this.localProducts.map((el) => ({
        cancellationComment,
        cancellationReason,
        orderId: el.orderId,
        productId: el.productId,
      }));
      this.$api.post('/api/v1/crm/orders/canceled-order-items', body)
        .then(() => {
          this.showCancellationDialog = false;
          this.cancellationItem = {};
          this.$emit('updateOrder');
          this.$emit('close');
        })
        .catch(async (error) => {
          if (error) {
            await this.$store.dispatch('tools/setSnackbar', {
              type: 'error',
              message: 'При отмене позиции возникла ошибка',
            });
          }
        })
        .finally(() => {
          this.$loading(false);
        });
    },
    /** Актуализация списка товаров и обновление всего заказа */
    onCancellationDialogClose(data) {
      if (data && data.productId) {
        const cancelledProductIndex = this.localProducts
          .findIndex((i) => i.productId === data.productId);
        if (cancelledProductIndex !== -1) {
          const obj = {
            ...this.localProducts[cancelledProductIndex],
            cancellable: false,
            editing: false,
            factAmount: '',
          };
          this.localProducts.splice(cancelledProductIndex, 1, obj)
        }
      }
      this.showCancellationDialog = false;
      this.cancellationItem = {};
      this.$emit('updateOrder');
    },
    closeSMSDialog() {
      this.showSMSCode = false;
      this.clicked = false;
    },
    verifyComplete(dtoList) {
      this.dtoList = dtoList;
      if (this.orderStatus === 'ON_DELIVERY') {
        this.sendSMS();
      } else {
        this.$emit('confirm', dtoList);
      }
    },
    sendSMS() {
      this.showSMSCode = false;
      const body = {
        primary: true,
        // recipient: this.normalize(this.contactPhone) || this.user.username,
        recipient: this.normalize(this.user.username),
        androidAutofillVerification: `для заказа №${this.orderId}`,
      };
      this.$sms.post('/v1/SmsGate/code', body)
        .then((res) => {
          this.showSMSCode = true;
          this.messageResponseId =  res.content.messageResponseId;
        }).catch(async (error) => {
        if (error) {
          console.error(`STATUS:  ${error?.response?.status} ${error?.response.data}`)
          this.$store.dispatch('tools/setSnackbar', {
            type: 'error',
            message: `Не удалось отправить смс-код для заказа №${this.orderId}`,
          });
        }
      });
    },
    complete(sms) {
      this.showSMSCode = false;
      const dto = {
        messageId: this.messageResponseId,
        verificationCode: sms,
        items: this.dtoList,
      };
      this.$emit('confirm', dto);
    },
    normalize(phone) {
      if (phone) {
        return phone.replace(/\s+/g, '').replace(/\+/g, '');
      }
      return '';
    },
  },
};
</script>
<style lang="scss">
.commission-v-dialog {
  &.v-dialog {
    border-radius: 0;
  }
}
.complete-order-dialog {
  border-radius: 0!important;
  &__actions {
    &.v-card__actions {
      padding: 12px 20px!important;
    }
    .v-btn {
      border-radius: 18px;
    }
  }
}
</style>
