<template>
  <v-dialog v-model="open"
            @keydown.esc="$emit('close')"
            @click:outside="$emit('close')"
            width="600px">
    <v-card :loading="loading">
      <v-card-title>
        <v-icon class="mx-1" color="error">
          mdi-alert
        </v-icon>
        Завершение заказа с незавершенным отбором
      </v-card-title>
      <v-card-text >
        <p class="alert">
          Заказ оформлен в рассрочку. <strong>Неполный</strong> отбор приведет к отмене заказа.
        </p>
        <p>Вы уверены, что хотите завершить отбор?</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="confirm()">
          Да
        </v-btn>
        <v-btn color="primary" @click="$emit('close')">
          Нет
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'SelectionConfirm',
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    confirm() {
      this.$emit('confirm');
    },
  },
};
</script>
<style scoped>
p.alert {
  background-color: #f15b3b;
  line-height: 1.4;
  color: #ffffff;
  padding: 10px;
  border-radius: 5px;
  margin-top: 20px;
}
</style>
