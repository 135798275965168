<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <crm-table-pageable-new
            :headers="headers"
            :data="data"
            :config="config"
            :loaded-options="options"
            :total-elements="totalElements"
            :total-pages="totalPages"
            :filters-dictionaries="filtersDictionaries"
            :available-filters.sync="availableFilters"
            @onFocusSelectFilter="getStatuses"
            @onChangeData="onChangeData"
            @handleActions="handle"
        >
          <template #last-slot>
            <div style="width: 100%; display: flex; justify-content: flex-end">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                left
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn :loading="exportLoading"
                         v-bind="attrs"
                         v-on="on"
                  > Скачать</v-btn>
                </template>
                <v-date-picker
                  v-model="date"
                  range
                  @input="onPickerInput"
                ></v-date-picker>
              </v-menu>

            </div>

          </template>
        </crm-table-pageable-new>

      </v-col>
    </v-row>
    <order-info v-if="orderVisible" :visible="orderVisible"
                :id="orderId" @closed="handleClose"/>
  </v-container>
</template>

<script>
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';
import OrderInfo from '@/views/crm/orders/OrderInfo.vue';
import XLSX from 'xlsx';
import moment from 'moment';
import { mapState } from 'vuex';
import {formatPhoneNumber} from "@/utils/helper";

export default {
  name: 'index',
  components: {
    OrderInfo,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'id', sortable: false, text: 'Заказ №', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'enums', enums: 'orderStatusEnums',
        },
        {
          value: 'paymentStatus', sortable: false, text: 'Статус оплаты', type: 'enums',
        },
        {
          value: 'createdDate', sortable: true, text: 'Дата и время создания', type: 'datetime',
        },
        {
          value: 'address.contactPerson', sortable: false, text: 'Клиент', type: 'defaultItem', class: 'client',
        },
        {
          value: 'contactPhone', sortable: false, text: 'Телефон', type: 'defaultItem',
        },
        {
          value: 'totalPriceWithoutPromoCode', sortable: false, text: 'Сумма заказа', type: 'defaultItem',
        },
        {
          value: 'paymentType', sortable: false, text: 'Способ оплаты', type: 'enums', enums: 'paymentTypeEnums',
        },
        {
          value: 'receiptType', sortable: false, text: 'Способ доставки', type: 'enums', enums: 'receiptTypeEnums',
        },
        {
          value: 'promo.event.discount', sortable: false, text: 'Промокод', type: 'defaultItem',
        },
        {
          value: 'totalPrice', sortable: false, text: 'Сумма заказа', type: 'defaultItem',
        },
        {
          text: 'Действие',
          value: 'actions',
          type: 'actions',
          sortable: false,
          child: [
            {
              label: 'Редактировать',
              event: 'edit',
              icon: 'mdi-pencil',
              visible: true,
            },
          ],
        },
      ],
      data: [],
      orderVisible: false,
      orderId: null,
      config: {
        search: true,
        searchPlaceholder: 'Поиск по номеру заказа',
        pageable: true,
      },
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/orders',
      options: {
        page: 1,
        size: 10,
        sort: 'createdDate,desc',
      },
      exportLoading: false,
      date: null,
      menu2: null,
      filtersDictionaries: {},
      availableFilters: [
        {
          id: 'statusList', name: 'Статус заказа', type: 'select', active: true,
        },
        {
          id: 'createdDateRange', name: 'Дата', type: 'dateRange', menu: false, active: false,
        },
      ],
    };
  },
  computed: {
    statusEnums() {
      return this.$store.getters['entity/getEntity']('statusEnums');
    },
    paymentTypeEnums() {
      return this.$store.getters['entity/getEntity']('paymentTypeEnums');
    },
    ...mapState('entity', ['orderStatusEnums', 'enums']),
    ...mapState('crm', ['ordersStatuses']),
  },
  created() {
    // await this.getStatuses();
    this.onChangeData(this.$route.query);
  },
  methods: {
    getStatuses() {
      this.$store.dispatch('crm/actionGetOrdersStatuses')
        .then(() => {
          this.$set(this.filtersDictionaries, 'statusList', this.ordersStatuses.map((i) => ({
            value: i.status,
            label: `${this.orderStatusEnums[i.status]} (${i.count})`,
          })));
        });
    },
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getOrders();
    },
    getOrders() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
          search: params.search,
          statusList: params.statusList,
          from: params.createdDateRange && params.createdDateRange.split(',')[0],
          to: params.createdDateRange && params.createdDateRange.split(',')[1],
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.data = response.content.map(c => ({
          ...c,
          contactPhone: formatPhoneNumber(c.address?.contactPhone ?? c.user?.phoneNumber)
        }));

        this.getStatuses();
      }).finally(() => {
        this.$loading(false);
      });
    },
    handle(event) {
      this.orderId = event.item.id;
      this.orderVisible = true;
    },
    handleClose() {
      this.orderVisible = false;
      this.orderId = null;
      this.onChangeData(this.$route.query);
    },
    async exportExcel(from, to) {
      this.exportLoading = true;
      const query = `from=${from}&to=${to}`;
      const response = await this.$api.get(`/api/v1/crm/report/orders?${query}`);
      const workBook = XLSX.utils.book_new();
      if (response) {
        const data = response.map((i) => ({
          'Заказ №': i.id,
          Статус: this.orderStatusEnums[i.status],
          'Статус оплаты': this.enums[i.paymentStatus],
          'Дата и время создания': moment(i.createdDate).format('DD.MM.YYYY HH:mm'),
          Телефон: i.user.phoneNumber,
          Сумма: i.totalPrice,
        }));
        const workSheet = XLSX.utils.json_to_sheet(data);
        XLSX.utils.book_append_sheet(workBook, workSheet);
        const exportFileName = 'report.xls';
        XLSX.writeFile(workBook, exportFileName);
      }
      this.exportLoading = false;
    },
    onPickerInput() {
      if (this.date.length === 2) {
        this.menu2 = false;
        if (this.$moment(this.date[0]).isAfter(this.date[1])) {
          this.date = [...this.date].reverse();
        }
        this.exportExcel(this.date[0], this.date[1]);
      }
    },
  },
};
</script>

<style lang="scss">

</style>
