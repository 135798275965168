<template>
  <section class="pdf-content">
    <p style="text-align: center; font-size: large;"><strong>ОТБОР</strong></p>
    <p><span style="font-weight: 400;">Номер заказа: <strong>{{ data.id }}</strong></span></p>
    <p>
        <span style="font-weight: 400;">
          Дата и время: <strong>{{ nowDateTime }}</strong>
        </span>
    </p>
    <p><span style="font-weight: 400;">Клиент: <strong>{{ fullName }}</strong></span></p>
    <p><span style="font-weight: 400;">
      Номер телефона: <strong>{{ data.user.phoneNumber }}</strong>
    </span></p>
    <p>
        <span style="font-weight: 400;">
          Адрес доставки: <strong>{{ fullAddress }}</strong>
        </span></p>
    <hr>
    <br />
    <br />
    <p style="text-align: end">
      <span style="font-weight: 700;">Итоговая сумма: {{ data.totalPrice }}</span>
    </p>
    <table>
      <tbody>
      <tr style="">
        <th style="width: 4%;height: 56px; text-align: center;">
          <p><strong>№</strong></p>
        </th>
        <th style="width: 8%;">
          <p><strong>Фото</strong></p>
        </th>
        <th style="">
          <p><strong>Наименование товара</strong></p>
        </th>
        <th style="width: 19%;">
          <p><strong>Штрихкод товара</strong></p>
        </th>
        <th style="width: 9%;">
          <p><strong>Кол-во заказано</strong></p>
        </th>
        <th style="width: 10%;">
          <p><strong>Цена за товар</strong></p>
        </th>
        <th style="width: 10%;">
          <p><strong>Стоимость заказано</strong></p>
        </th>
      </tr>
      <tr v-for="(item, index) in data.items.filter(item => item.status !== 'CANCELED')"
          :key="item.id"
          :class="(index + 1) % 12 === 0 && 'html2pdf__page-break'">
        <td>{{ index + 1 }}</td>
        <td><img :src="item.imageUrl.preAssignedUrl" height="48px" width="48px"></td>
        <td>{{ getFullItemTitle(item) }}</td>
        <td>{{ item.barcodeList.join(', ') }}</td>
        <td style="text-align:right">{{ item.planAmount }}</td>
        <td style="text-align:right">{{ item.price }}</td>
        <td style="text-align:right">{{ item.totalPrice }}</td>
      </tr>
      </tbody>
    </table>
    <br />
    <br />
    <br />
    <p><span style="font-weight: 400;">Ответственный ________________________</span></p>
    <h1 style="color: #5e9ca0;"><br><br><br></h1>
  </section>
</template>

<script>
import moment from 'moment';

export default {
  props: ['data'],
  computed: {
    nowDateTime() {
      return moment(new Date()).format('DD.MM.YYYY HH:mm:ss');
    },
    fullAddress() {
      return `${this.data.address?.city}, ${this.data.address?.street}, ${this.data.address?.building}, ${this.data.address?.apartment}`;
    },
    fullName() {
      const { user } = this.data;
      return `${user.lastName || ''} ${user.firstName || ''}`.trim() || user.username;
    },
  },
  methods: {
    getFullItemTitle(item) {
      const {
        nameRu,
        // brand,
        color,
        size,
      } = item;
      let title = nameRu;
      if (title && color && color.nameRu) {
        title += `/ ${color.nameRu}`;
      }
      if (title && size) {
        title += `/ ${size}`;
      }
      return title;
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit('domRendered');
      }, 1000);
    });
  },
};
</script>

<style lang="scss" scoped>
.pdf-content {
  width: 100%;
  background: #fff;
  padding: 48px;
}
table, th, td {
  border: 1px solid #999999;
  border-collapse: collapse;
  padding: 0 4px;
}
</style>
